<template>
    <div :key="pageKey">
        <appic-page-title-bar ref="breadcrumbBar"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <app-card
                :fullBlock="true"
                colClasses="xl12 lg12 md12 sm12 xs12"
            >
                <UsersListing/>
            </app-card>
        </v-container>
    </div>
</template>

<script>
import LoadingComponent from "Components/Appic/LoadingComponent";
import {mapFields} from "vuex-map-fields";

const UsersListing = () => ({
    component: import("Components/Appic/UsersListing"),
    loading: LoadingComponent
})

export default {
    name: "UsersOverview",
    components: {UsersListing},
    title: '',
    data() {
        return {
            loader: false,
            pageKey: Math.floor(Math.random() * 100),
        }
    },
    computed: {
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
    },
    mounted() {
        this.$title = this.$t('message.titles.users')
    }
}
</script>

<style>
.font-xs {
    font-size: 0.65rem;
}
.mt-5px {
    margin-top: 5px !important;
}
.mt-6px {
    margin-top: 6px !important;
}
</style>